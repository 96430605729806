import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import Base_Url from "../api/baseUrl";
import axios from "axios";
import EndOfArticle from "../Components/ads/EndOfArticle";
import BetweenContent from "../Components/ads/BetweenContent";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import decrypt from "../Components/Common/DecryptData";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const ViewPerfumeData = () => {
  const location = useLocation();
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");
  const [perfumeData, setPerfumeData] = useState();
  const [loading, setLoading] = useState();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  useEffect(() => {
    const getPerfumeDataById = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${Base_Url}api/life-style/perfume/${formattedTitle}`
        );

        const { iv, end } = res.data;

        const decryptedDataString = decrypt(iv, end);
        const decryptedData = JSON.parse(decryptedDataString);

        if (decryptedData) {
          setPerfumeData(decryptedData.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log("Error fetching view perfume data:", error);
      }
    };

    if (formattedTitle) {
      getPerfumeDataById();
    }
  }, [formattedTitle]);

  return (
    <>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      {loading ? (
        <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
          <div className="loader2 my-5"></div>
        </div>
      ) : (
        <div className="container max-w-7xl mx-auto px-4">
          <header className="text-center py-8">
            <h1
              className="font-bold"
              style={{
                fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
              }}
            >
              {perfumeData?.single?.title}
            </h1>
            <div className="mt-2 flex justify-center mx-auto">
              <img
                src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/millionairclubs/Layer_1.webp"
                alt=""
              />
            </div>
          </header>
          {/* <LongAd /> */}
          <EndOfArticle />
          <section className="text-center py-4">
            <img
              src={perfumeData?.single?.image}
              alt=""
              className="md:h-auto h-[200px]"
            />
            <div
              className="mt-5 md:max-w-[80%] mx-auto text-[#252525]"
              style={{
                fontSize: "clamp(0.9rem, 2vw, 1.2rem)",
              }}
              dangerouslySetInnerHTML={{
                __html: perfumeData?.single?.description,
              }}
            />
          </section>
          {/* <LongAdCard /> */}
          <BetweenContent />
          <div className="grid grid-cols-1 md:max-w-[80%] mx-auto gap-8 md:mt-28 mt-5">
            {perfumeData?.single?.subProducts?.map((item, index) => (
              <div
                className="grid grid-cols-1 md:grid-cols-12 gap-8 items-start"
                key={index}
              >
                <div className="h-[350px] w-[100%] lg:col-span-3 md:col-span-5">
                  <img
                    src={item?.image}
                    alt={item?.title}
                    className="w-full h-full "
                  />
                </div>
                <div className="space-y-4 text-center md:text-start lg:col-span-9 md:col-span-7">
                  <h2 className="text-2xl font-semibold">{item?.title}</h2>
                  <div
                    className="text-[#252525]"
                    style={{
                      fontSize: "clamp(0.9rem, 2vw, 1.2rem)",
                    }}
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                </div>
              </div>
            ))}
          </div>
          {/* <LongAdCard02 /> */}
          <BelowFeaturedImages />
          <div>
            <h3
              className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(2rem, 4vw, 3rem)",
              }}
            >
              Perfumes & Fragrances
            </h3>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-20 mb-12">
            {perfumeData?.Perfume?.filter(
              (x) => x?.id !== parseInt(querySlug)
            )?.map((data, index) => {
              return (
                <div
                  onClick={() => {
                    const formattedTitle = data?.title?.replace(/\s+/g, "-");
                    window.location.href = `/view-perfume-data/${formattedTitle}`;
                  }}
                  className="cursor-pointer"
                  key={index}
                >
                  <div className="h-[230px] mx-auto lg:w-[100%] w-full">
                    <img
                      src={data?.image}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <p className="mx-auto font-medium text-[17px] w-full mt-2">
                    {data?.title}
                  </p>
                </div>
              );
            })}
          </div>
          <BackToTop />
        </div>
      )}
    </>
  );
};

export default ViewPerfumeData;
